import {Select, Switch } from "@chakra-ui/react";
import React, { useState ,useEffect} from "react";
import Notifications from "./Notifications";
import UserMenu from "./UserMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import HeadSettings from "./HeadSettings";
import HelpDesk from "./HelpDesk";
import { useAuth } from "../Utils/AuthContext";
import RaiseIssue from "../Utils/RaiseIssue";
import authApi from "../Utils/AuthApis";
import { useNavigate } from "react-router-dom";

function Header({ sidebarOpen, setSidebarOpen }) {
  const { userData, HandleSessionModal } = useAuth();
  const navigate = useNavigate();
  const [branch,setBranch] = useState([]);
  const [ActiveBranch, setActiveBranch] = useState(userData?.isActiveBranch);
  const [MenuList, setMenuList] = useState([]);
  const [ActiveRoleLayout, setActiveRoleLayout] = useState(userData?.isRoleBased);

  console.log("role Layout",ActiveRoleLayout);
  useEffect(()=>{
    const getBranchList = async () => {
      try {
        const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/Branch/GetBranchList?CompanyId=${userData.companyId}`)
        // console.log( "Branch Data ",response.data);
        let res = response.data;
        setBranch(res.filter(data => data.isActive === true));
        setActiveBranch(userData.isActiveBranch);
      } catch (error) {
        console.error(error);
      }
    }
    getBranchList();
  },[userData]);

  useEffect(() => {
    HandleSelectedRole();
  }, []);
  

  const HandleBranchChange = async (ActiveBranchId,RoleLayoutId)=>{

   let body = {
    userid: userData?.userId,
    branchId: ActiveBranchId,
    isRoleBased: RoleLayoutId,
    userName: userData?.userName
   }

  console.log("Active Branch Body",body);

   await authApi.post(
     `${process.env.REACT_APP_ACQ_URL}/api/Branch/UpdateActiveBranch?Userid=${userData?.userId}&BranchId=${ActiveBranchId}&UserName=${userData?.userName}&IsRoleBased=${Boolean(RoleLayoutId)}`
    ).then((response) => {
      console.log("response",response);
      navigate('/dashboard')
      // window.location.reload(true);
    })
    .catch((err) => {
      console.error(err.response);
      if(err.response){
        if(err.response.statusText === "Unauthorized" || err.response.status === 401){
            HandleSessionModal();
        }
      }
    });

  }


  const HandleSelectedRole = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/GetModuleMasterList?RoleId=${userData?.roleId || 0}&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
      );
      // console.log("Menu List ", response);
      setMenuList(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  const CheckMainMenuAccess = (ReqMainMenuName) => {
    return MenuList?.some((data) => {
     if (data.mainMenuName === ReqMainMenuName) {
       const UserAccess = userData?.isRoleBased ? (data.isCreate || data.isModify || data.isView ) : data.isDefault;
       return UserAccess;
     }
     return false;
    }); 
  }


  return (
    <header className="sticky top-2 mx-2 rounded-2xl bg-slate-800 border-b border-slate-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-14 -mb-px">
          {/* Header: Left side */}
          <div className="flex">
            {/* Hamburger button */}
            <button
              className="text-white mr-3 hover:text-slate-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={(e) => {
                e.stopPropagation();
                setSidebarOpen(!sidebarOpen);
              }}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>

            {userData?.role === "Admin" ?
                          <div className="mr-4">
                          <Select onChange={({target})=>{
                             setActiveBranch(target.value);
                             HandleBranchChange(target.value);
                             }} value={ActiveBranch}  bg='white' size='sm' borderRadius={8} placeholder="Select Branch">
                   {branch.map((data,i)=>(
                      <option key={i} value={data.branchId}>{data.branchName}</option>
                   ))}
                  </Select>
                          </div> :
                          <>
             
            {MenuList.filter((data)=>data.mainMenuName === "Branch").map(({isCreate,isModify,isView})=>{
              if(isCreate || isModify === true){
                return <div className="mr-4">
                 <Select onChange={({target})=>{
                    setActiveBranch(target.value);
                   HandleBranchChange(target.value,ActiveRoleLayout);
                   }} value={ActiveBranch} bg='white' size='sm' borderRadius={8}>
                  {branch.map((data,i)=>(
                    <option key={i} value={data.branchId}>{data.branchName}</option>
                  ))}
                 </Select>
                </div>
              }

              if(isView){
                return <div className="my-auto mr-4">
                {branch.map((data,i) => {
                  if(data.branchId === ActiveBranch) {
                   return  <h6 key={i}><FontAwesomeIcon icon={faLocationDot} className="text-white mr-2" /><span className='font-semibold text-gray-50'>{data.branchName}</span> </h6>
                  }
                })}
              </div>
              }
              })}
            </>
          }

            <div className="my-auto">
             <h6><FontAwesomeIcon icon={faBuilding} className="text-white mr-2" /><span className='font-semibold text-gray-50'>{userData.companyName}</span> </h6>
            </div>
          </div>

          {/* Header: Right side */}
          <div className="flex items-center gap-3">
            {/* {userData?.role !== "Employee" && <div className="flex items-center gap-2">
             <label className="text-white font-semibold">{!ActiveRoleLayout ? 'Activate Role' : 'Deactivate Role'}?</label>
             <Switch 
              size="md" 
              isChecked={ActiveRoleLayout} 
              onChange={({target})=>{ 
                setActiveRoleLayout(target.checked); 
                HandleBranchChange(ActiveBranch,target.checked); 
              }} 
              colorScheme="green" 
             />
            </div>} */}
             {userData.role === "Admin" && <HelpDesk />}
             <Notifications />
             <RaiseIssue />
             {CheckMainMenuAccess("Setting") && <HeadSettings /> }
             <hr className="w-px h-6 bg-slate-200 " />
             <UserMenu />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
